<template>
  <div class="staff-details-page">
    <v-card v-if="staff && staff.disabled" color="yellow lighten-4">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-wrap body-2"
            >The staff account has been
            <strong>'Deactivated'</strong></v-list-item-title
          >
        </v-list-item-content>

        <v-list-item-icon
          ><v-btn @click="showActivateDialog = true" small color="primary"
            ><v-icon class="mr-2">mdi-account-check</v-icon> Activate</v-btn
          ></v-list-item-icon
        >
      </v-list-item>
    </v-card>
    <v-row justify="center">
      <!-- staff details -->
      <v-col md="6">
        <div class="mt-6">
          <div class="text-center">
            <v-avatar v-if="staff" size="120px">
              <v-img :src="staff.image"></v-img>
            </v-avatar>
          </div>
          <v-divider class="mb-4 mt-6"></v-divider>
          <v-card :elevation="$vuetify.breakpoint.mdAndDown ? '0' : null">
            <v-list-item
              class="non-focused"
              v-for="(item, index) in profileItems"
              :key="index"
              :href="item.href || null"
              :to="item.to || null"
              @click="() => (item.action ? item.action() : {})"
            >
              <v-list-item-avatar class="align-self-center">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>{{ item.heading }}</v-list-item-subtitle>
                <v-list-item-title :class="item.valueClasses">{{
                  item.value
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon class="align-self-center" v-if="item.appendIcon"
                ><v-icon>{{ item.appendIcon }}</v-icon></v-list-item-icon
              >
            </v-list-item>
            <v-list-item v-if="staff">
              <v-list-item-avatar
                ><v-icon>mdi-account-check</v-icon></v-list-item-avatar
              >
              <v-list-item-content>
                <v-list-item-title>Roles</v-list-item-title>
                <v-list-item-subtitle
                  ><v-chip
                    color="red lighten-4"
                    class="mr-1"
                    v-if="staff.is_admin"
                    small
                    >Admin</v-chip
                  ><v-chip
                    v-if="staff.is_faculty"
                    color="green lighten-4"
                    class="mr-2"
                    small
                    >Faculty</v-chip
                  ><v-chip
                    v-if="staff.is_driver"
                    color="blue lighten-4"
                    class="mr-2"
                    small
                    >Driver</v-chip
                  ></v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-col md="6">
        <v-expansion-panels class="px-2 mb-3 pt-2" v-model="expanded">
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-medium"
              >Subjects Assigned ({{
                subjects.length
              }})</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-list class="text-left pa-0" v-if="subjects.length">
                <div v-for="subject in subjects" :key="subject.id">
                  <v-list-item
                    :to="subject.room ? {
                      name: 'ClassroomDetails',
                      params: { roomId: subject.room.id },
                    } : {
                      name: 'ManageExtraSubjects',
                    }"
                    class="non-focused px-2"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ subject.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        subject.room ? subject.room.sections : 'Extra Subjects'
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon
                      ><v-icon>mdi-arrow-right</v-icon></v-list-item-icon
                    >
                  </v-list-item>
                </div>
              </v-list>
              <div v-else-if="!isLoading" class="text-center">
                <strong class="ma-0">No subjects found</strong>
                <p class="ma-0 mt-1">
                  Assign subjects in the
                  <strong
                    ><router-link :to="{ name: 'AdminClassroomsList' }"
                      ><span class="primary--text"
                        >Classrooms</span
                      ></router-link
                    ></strong
                  >
                  section
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <!-- staff edit sheet -->
    <intract-create-edit-sheet
      persistent
      key="staff-edit-sheet"
      class="staff-edit-sheet"
      title="Edit Staff"
      :visible="editStaffSheet.visible"
      @close="
        editStaffSheet.visible = false;
        editStaffSheet.editId = null;
        editStaffSheet.obj.image = null;
      "
      :fields="staffCreationFormFields"
      submit-button-text="Edit"
      :endpoint="endpoints.staffViewSet"
      :edit-id="editStaffSheet.editId"
      :data-object="editStaffSheet.obj"
      :create-success-message="editStaffSheet.editSuccessMessage"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (editStaffSheet.obj = obj)"
    >
      <template v-slot:custom-field--image="{ obj }">
        <v-col class="text-center">
          <div>
            <v-avatar size="100px" class="mb-2">
              <v-img
                v-if="obj.image && obj.image.document"
                :src="obj.image.document"
              ></v-img>
              <v-img v-else :src="obj.image"></v-img
            ></v-avatar>
          </div>
          <v-btn
            class="my-2"
            color="primary"
            @click="$refs.profileImageField.$refs.input.click()"
            ><v-icon class="mr-3">mdi-image</v-icon
            ><span v-if="!obj.image">Add Image</span
            ><span v-else>Edit Image</span></v-btn
          >
          <v-file-input
            v-show="false"
            class="ma-0 pa-0"
            ref="profileImageField"
            @change="(file) => addProfileImage(file, obj)"
            accept="image/*"
          ></v-file-input>
        </v-col>
      </template>
      <template v-slot:custom-field--username="{ obj }">
        <v-text-field
          v-model="obj.username"
          outlined
          :error-messages="usernameError"
          maxlength="30"
          hide-details="auto"
          :rules="usernameRules"
          @input="checkUsernameExists(obj.username)"
        >
          <template #label>
            Username<span class="red--text"><strong> * </strong></span>
          </template>
        </v-text-field>
        <v-col cols="12" v-if="obj.phone || obj.full_name">
          Suggestions:
          <v-chip
            class="mx-1"
            v-if="obj.phone && obj.username != obj.phone"
            @click="
              obj.username = obj.phone;
              checkUsernameExists(obj.username);
            "
            >{{ obj.phone }}</v-chip
          >
          <v-chip
            class="mx-1"
            v-if="
              obj.full_name && obj.username != convertToUsername(obj.full_name)
            "
            @click="
              obj.username = convertToUsername(obj.full_name);
              checkUsernameExists(obj.username);
            "
            >{{ convertToUsername(obj.full_name) }}</v-chip
          >
        </v-col>
      </template>
    </intract-create-edit-sheet>

    <image-cropper-dialog
      ref="cropperDialog"
      :uploadedImage="uploadedImage"
      @onCrop="(croppedImage) => {
        updateObj.image = {
          document: croppedImage,
          file_name: imageName,
        };
      }"
    />

    <!-- delete staff confirmation -->
    <confirmation-dialog
      :visible="showDeleteDialog"
      title="Are you sure you want to delete this staff?"
      description="This staff will no longer show up anywhere inside the app."
      @successCallback="deleteStaff"
      @failureCallback="showDeleteDialog = false"
      delete-dialog
    />
    <!-- deactivate staff confirmation -->
    <confirmation-dialog
      :visible="showDisableDialog"
      title="Are you sure you want to deactivate this staff's account?"
      description="This will deactivate the their account and will block them from logging in unless enabled again by the admins."
      @successCallback="disableStaff"
      @failureCallback="showDisableDialog = false"
      delete-dialog
    />
    <!-- activate staff confirmation -->
    <confirmation-dialog
      :visible="showActivateDialog"
      title="Are you sure you want to activate this staff's account?"
      description="This allow them to login inside the app again."
      @successCallback="activateStaff"
      @failureCallback="showActivateDialog = false"
      delete-dialog
    />
    <!-- reset password dialog -->
    <confirmation-dialog
      :visible="showResetPasswordDialog"
      title="Are you sure you want to reset this staff member's password?"
      :description="`Resetting will set their password as their phone number. The new password will be <b>${
        staff ? staff.phone : ''
      }</b>.`"
      @successCallback="resetPassword"
      @failureCallback="showResetPasswordDialog = false"
    />
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import ImageCropperDialog from "@components/dialogs/ImageCropperDialog"
import debounce from "debounce";
import { mapGetters, mapActions } from "vuex";
import EventBus from "@utils/event_bus";
import { Share } from '@capacitor/share';

export default {
  name: "StaffDetails",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    IntractCreateEditSheet,
    ImageCropperDialog,
  },
  data() {
    return {
      expanded: 0,
      usernameError: null,
      usernameRules: [
        (v) => !!v || v === 0 || "Required",
        (v) => /^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+$/.test(v) || "Invalid",
        (v) => !v || v.length >= 5 || "Should be minimum 5 characters",
      ],
      staff: null,
      showDeleteDialog: false,
      showDisableDialog: false,
      showActivateDialog: false,
      subjects: [],
      showResetPasswordDialog: false,
      editStaffSheet: {
        visible: false,
        editSuccessMessage: "Staff edited successfully!",
        editId: null,
        obj: {
          is_faculty: true,
          is_admin: false,
          is_student: false,
          institution: null,
        },
      },
      platform: null,
      uploadedImage: null,
      updateObj: null,
      imageName: null,
    };
  },
  computed: {
    ...mapGetters(["allRooms", "currentInstitution"]),
    profileItems() {
      if (!this.staff) return [];
      return [
        {
          heading: "Full Name",
          value: this.staff.full_name,
          icon: "mdi-rename-box",
        },
        {
          heading: "Username",
          value: this.staff.username,
          icon: "mdi-account",
          valueClasses: "staff-details-username",
          action: async () => {
            if (this.platform == "web") {
              await this.Helper.copyText(this.staff.username);
              this.showSnackbar({
                title: "Username copied to clipboard!",
                type: "success",
              });
            } else {
              Share.share({
                text: this.staff.username,
              });
            }
          },
          appendIcon: "mdi-content-copy",
        },
        {
          heading: "Phone",
          value: this.staff.phone,
          icon: "mdi-phone",
          href: "tel:" + this.staff.phone,
          appendIcon: "mdi-arrow-right",
        },
      ];
    },
    staffCreationFormFields() {
      return {
        image: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          // pattern: /^(?=.{8,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/,
        },
        full_name: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Full Name",
          required: true,
          md: 6,
          max: 255,
        },
        phone: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Phone",
          inputmode: "numeric",
          required: true,
          md: 6,
          max: 12,
          input: "phone",
          customRules: [
            (v) => v == null || v.length >= 10 || "Should be atleast 10 digits",
          ],
          pattern: /^(0|[1-9][0-9]*)$/,
        },
        username: {
          md: 6,

          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
        },
        is_admin: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Admin?",
          md: 12,
          helper: "Assign 'Admin' role to staff",
        },
        is_driver: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Driver?",
          md: 12,
          helper: "Assign 'Driver' role to staff",
          hide: !JSON.parse(this.currentInstitution.features).includes(7)
        },
      };
    },
  },
  methods: {
    ...mapActions(["setAppBarOptions", "resetAppBarOptions"]),

    convertToUsername(name) {
      return name.replace(/ /g, ".").trim().toLowerCase();
    },

    checkUsernameExists: debounce(async function (username) {
      if (!username) {
        this.usernameError = null;
        return;
      }
      var url = this.endpoints.checkUsernameExists + `?username=${username}`;
      if (await this.api.call(this.essentials, url)) {
        this.usernameError = "Username Exists";
      } else {
        this.usernameError = null;
      }
    }, 500),

    async addProfileImage(file, obj) {
      if (!file) return;
      this.uploadedImage = await this.Helper.toBase64(file);
      this.imageName = file.name; this.updateObj = obj;
      this.$refs.cropperDialog.initCropper(file.type);
    },

    async getStaffDetails() {
      var url = this.endpoints.staffViewSet + this.$route.params.staffId + "/";
      this.staff = await this.api.call(this.essentials, url);
      EventBus.$emit("staff_details__staff_updated", this.staff);
    },

    async getStaffSubjects() {
      var url = this.Helper.addUrlParams(
        this.endpoints.subjectViewSet,
        "faculty=" + this.$route.params.staffId
      );
      this.subjects = await this.api.call(this.essentials, url);
    },

    async resetPassword() {
      var url = `${this.endpoints.staffViewSet}${this.$route.params.staffId}/auth/password-reset/`;
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.POST
      );
      if (response) {
        this.showResetPasswordDialog = false;
        this.showSnackbar({
          title: "Password successfully reset!",
          text: `The new password of this staff member is <b>${this.staff.phone}</b>`,
          type: "success",
        });
      }
    },

    async disableStaff() {
      this.showDisableDialog = false;
      var url = this.endpoints.staffViewSet + this.staff.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { disabled: true }
      );
      if (response) {
        this.staff.disabled = true;

        EventBus.$emit("staff_details__staff_deleted", this.staff.id);
        this.showSnackbar({
          title: "Staff has been deactivated!",
          text: "The staff will no longer be able to login inside the app.",
          type: "success",
        });
      }
    },
    async activateStaff() {
      this.showActivateDialog = false;
      var url = this.endpoints.staffViewSet + this.staff.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { disabled: false }
      );
      if (response) {
        this.staff.disabled = false;
        EventBus.$emit("staff_details__staff_added", this.staff);
        this.showSnackbar({
          title: "Staff has been activated!",
          text: "The staff can now continue using the app as usual.",
          type: "success",
        });
      }
    },

    async deleteStaff() {
      this.showDeleteDialog = false;
      var url = this.endpoints.softDeleteStaffRecords + this.staff.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        EventBus.$emit("staff_details__staff_deleted", this.staff.id);
        this.showSnackbar({
          title: "Staff successfully deleted!",
          text: "All records of the staffs have been removed",
          type: "success",
        });
        this.$router.back();
      }
    },

    async setAppBar() {
      this.setAppBarOptions({
        title: this.appBarTitle,
        actions: [
          {
            id: 1,
            title: "Edit Staff",
            icon: "mdi-pencil",
            onClick: () => {
              this.editStaffSheet.visible = true;
              this.editStaffSheet.editId = Number(this.$route.params.staffId);
            },
          },
          {
            id: 5,
            title: "Share Details",
            icon: "mdi-share",
            onClick: async () =>{
              if (this.platform == "web"){
                let text = `Hey ${this.staff.full_name}, download the ${this.endpoints.appTitle} app for our institution.\n\nInstitution Code: ${this.currentInstitution.code}\nLogin Username: ${this.staff.username}\n\nDownload Links:\n\nAndroid: ${this.endpoints.playStoreLink}\n\niOS: ${this.endpoints.appStoreLink}\n\nWeb: ${this.endpoints.webAppLink}`;
                await this.Helper.copyText(text);
                this.showSnackbar({
                  title: "Invitation link copied to clipboard!",
                  type: "success",
                });
              }
              else {
                Share.share({
                  title: `Check out the ${this.endpoints.appTitle} app`,
                  text: `Hey ${this.staff.full_name}, download the ${this.endpoints.appTitle} app for our institution.\n\nInstitution Code: ${this.currentInstitution.code}\nLogin Username: ${this.staff.username}\n\nDownload Links:\n\nAndroid: ${this.endpoints.playStoreLink}\n\niOS: ${this.endpoints.appStoreLink}\n\nWeb: ${this.endpoints.webAppLink}`,
                })
              }
            } 
          },
          {
            id: 2,
            title: "Reset Password",
            icon: "mdi-lock-reset",
            onClick: () => (this.showResetPasswordDialog = true),
          },
          ...(this.staff && !this.staff.disabled
            ? [
                {
                  id: 3,
                  title: "Deactivate Staff",
                  icon: "mdi-account-remove",
                  onClick: () => (this.showDisableDialog = true),
                },
              ]
            : []),
          {
            id: 4,
            title: "Delete Staff",
            icon: "mdi-delete",
            onClick: () => (this.showDeleteDialog = true),
          },
          
        ],
      });
    },

    async onRefresh() {
      this.getStaffDetails();
    },
  },

  beforeDestroy() {
    this.resetAppBarOptions();
  },

  async created() {
    await this.getStaffDetails();
    this.getStaffSubjects();
    this.editStaffSheet.obj.institution = this.currentInstitution.id;
    this.platform = await this.Helper.getDevicePlatform();
    this.setAppBar();
  },
};
</script>
<style scoped>
.v-application a {
  color: inherit !important;
}
</style>